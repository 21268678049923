<template>
  <div>
    <div class="container unselectable bg-white p-0">
      <div class="bg-white">
        <div class="position-relative d-block pt-4 pb-2 px-3">
          <div class="d-flex justify-content-left">
            <div class="img-container">
              <!-- <img /> -->
              <div class="rounded-circle bg-light border text-secondary avatar">A</div>
            </div>
            <div class="pl-3 align-items-center">
              <h6 class="m-0">Mr Ahmad Ali</h6>
              <div class="badge badge-light border rounded-pill fw-light">
                Paid
              </div>
              <div class="mt-3">
                <button type="button" class="btn btn-success align-middle">
                  <i class="ri-phone-line mr-2 align-middle"></i>Call
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="position-relative d-block mt-4">
          <ul class="nav nav-tabs-custom">
            <li
              :class="
                activeBtn == 'mydetails'
                  ? 'nav-item w-30 text-center active'
                  : 'nav-item w-30 text-center '
              "
              @click="activeBtn = 'mydetails'"
            >
              My Details
            </li>
            <li
              :class="
                activeBtn == 'chat'
                  ? 'nav-item w-30 text-center active'
                  : 'nav-item w-30 text-center '
              "
              @click="activeBtn = 'chat'"
            >
              Chat
            </li>
            <li
              :class="
                activeBtn == 'prescriptions'
                  ? 'nav-item w-30 text-center active'
                  : 'nav-item w-30 text-center '
              "
              @click="activeBtn = 'prescriptions'"
            >
              Prescriptions
            </li>
          </ul>
          <!-- <div class="d-flex justify-content-between align-items-center mt-4">
          <button type="button" class="btn" style="width: calc(100% / 3)">My Details</button>
          <button type="button" class="btn" style="width: calc(100% / 3)">Chat</button>
          <button type="button" class="btn" style="width: calc(100% / 3)">Prescriptions</button>
        </div> -->
        </div>
      </div>
      <div class="table-bg" v-if="activeBtn == 'chat'"></div>
      <div class="table-bg" v-if="activeBtn == 'mydetails'"></div>
      <div class="table-bg" v-if="activeBtn == 'prescriptions'">
        <div
          class="d-flex justify-content-between align-items-center p-3 bg-light"
        >
          <p class="mb-0 small text-muted w-80">Item</p>
          <p class="mb-0 small text-muted w-20">gm/ml</p>
        </div>
        <div class="bg-white p-3">
          <div
            class="d-flex justify-content-between align-items-center"
            v-for="(data, index) in dataTable"
            :key="index"
          >
            <p class="px-2 w-80">{{ data.item }}</p>
            <p class="px-2 w-20">{{ data.quantity }}</p>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <input
              class="w-80 form-control input-custom"
              placeholder="Type the medicine name.."
              v-model="itemName"
            />
            <input
              class="w-20 form-control input-custom"
              placeholder="0"
              v-model="itemQuantity"
            />
          </div>
          <button
            class="btn align-middle border w-100 mt-3"
            @click="addNewItem"
          >
            <i class="ri-add-line mr-2 align-middle"></i>Add New Item
          </button>
        </div>
      </div>
      <div class="px-3 py-4 border-top">
        <button
          type="button"
          class="btn btn-danger w-100"
          @click="endConsultation"
        >
          End Consultation
        </button>
      </div>

      <!-- <div class="row pt-3 pb-3">
        <div class="col-12">
          <div v-if="$route.query.tab && $route.query.tab == 'prescriptions'">
            This is prescriptions tab
          </div>
          <div v-if="$route.query.tab && $route.query.tab == 'chat'">
            This is chat tab
          </div>
          <div v-if="$route.query.tab && $route.query.tab == 'details'">
            This is details tab
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import API from "./../../utils/API";

export default {
  data() {
    return {
      activeBtn: "mydetails",
      dataTable: [],
      itemName: "",
      itemQuantity: "",
    };
  },
  methods: {
    endConsultation() {
      this.$router.push({
        path: `/doctor-app/consultation-requests`,
      });
    },
    addNewItem() {
      if (this.itemName != "" && this.itemQuantity != "") {
        this.dataTable.push({
          item: this.itemName,
          quantity: this.itemQuantity,
        });
        this.itemName = "";
        this.itemQuantity = "";
      }
    },
  },
  async mounted() {
    console.log(this.$route);
  },
};
</script>

<style scoped>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.avatar {
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
}
.fw-light {
  font-weight: 500;
}
.w-30 {
  width: calc(100% / 3);
}
.nav-tabs-custom li {
  color: #667085 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer !important;
  padding-bottom: 5px;
}
.nav-tabs-custom .active {
  color: red !important;
  border-bottom: 1px solid red !important;
}
.nav-tabs-custom .active {
  color: red !important;
}
.nav-tabs-custom .active:hover {
  color: red !important;
  border-bottom: 1px solid red !important;
}

.nav-tabs-custom li:hover {
  color: #4e576b !important;
  border-bottom: 1px solid #4e576b !important;
}
.w-20 {
  width: 20%;
}
.w-80 {
  width: calc(80% - 1rem);
}
.input-custom::placeholder {
  font-size: 0.8rem !important;
  color: #ced4da !important;
}
.table-bg {
  min-height: calc(100vh - 269px);
}
</style>